<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="page-container-card primary--border" outlined>
                    <v-card-title class="title">
                        Media
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                            <add-button permission="notice-read" @action="form.dialog = true, $refs.form ? $refs.form.reset(): ''">
                                &nbsp; New Media
                            </add-button>
                        </transition>
                    </v-card-title>


                    <v-data-table
                            :headers="headers"
                            :hide-default-footer="form.items.data.length<11"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            sort-by="id"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.title }}</td>
                                <td class="text-xs-left">{{ item.description }}</td>
                                <td class="text-xs-left">
                                    <span v-if="item.status === 1"><v-icon color="green">check_circle</v-icon></span>
                                    <span v-if="item.status === 0"><v-icon color="red">cancel</v-icon></span>
                                </td>
                                <td class="text-xs-left">
                                    <span class="public" v-if="item.visibility === 1">Public</span>
                                    <span class="both" v-if="item.visibility === 2">Both</span>
                                    <span class="private" v-if="item.visibility === 0">Private</span>
                                </td>
                                <td class="text-xs-left"><a :href="item.file" target="_blank">{{item.file ? 'File':''}}</a> </td>
                                <td class="text-xs-right">
                                    <edit-button permission="notice-read"
                                                @agree="form.edit(item)"/>
    <!--                                <delete-button permission="notice-read" @agree="form.delete(props.item.id)"/>-->
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                v-model="valid"
                                :lazy-validation="lazy"
                        >
                            <v-text-field outlined dense autocomplete="off" label="Title*"
                                          required class="pa-0 pt-3"
                                          v-model="form.title"
                                          name="title" :error-messages="form.errors.get('title')"
                                          :rules="[v => !!v || 'Title Field is required']"/>

                            <v-select outlined dense :items="statuses" class="pa-0 pt-3" label="Status"
                                      name="status" v-model="form.status" :error-messages="form.errors.get('status')"
                                      :rules="[v => !!v || 'Status Field is required']"/>

                            <v-select outlined dense :items="visibilites" class="pa-0 pt-3" label="Visibility"
                                      name="visibility" v-model="form.visibility" :error-messages="form.errors.get('visiblity')"
                                      :rules="[v => !!v || 'Visibilty Field is required']"/>
                            <v-textarea outlined dense label="Description"
                                        v-model="form.description"
                                        :rules="[v => !!v || 'Description Field is required']"/>


                            <v-flex xs12 text-center class="ma-0 pa-0 justify-center align-center">
                                <v-scroll-y-transition>
                                    <v-btn  outlined @click="onPickFile" v-if="!imagePicker.url">
                                        <v-icon left dark small>add_a_photo</v-icon>
                                        Select Document
                                    </v-btn>
                                </v-scroll-y-transition>

                                <input type="file" style="display: none" ref="image" accept="image/*"
                                       @change="onFilePicked">
                            </v-flex>
                            <v-scroll-y-reverse-transition>
                                <v-flex xs12 text-center class="mb-2" v-if="imagePicker.url"
                                        style="position: relative;">
                                    <v-btn  outlined icon color="green" @click="onPickFile">
                                        <v-icon small>compare_arrows</v-icon>
                                    </v-btn>
                                    <v-avatar :size="112" color="grey lighten-4">
                                        <img :src="imagePicker.url" alt="avatar">
                                    </v-avatar>
                                    <v-btn outlined icon color="red" @click="resetImagePicker">
                                        <v-icon small>close</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-scroll-y-reverse-transition>

<!--                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>-->

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" text @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" text @click="validate">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import ImageCompressor from "image-compressor.js";
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            valid: true,
            lazy: false,
            form: new Form({
                title: '',
                description: '',
                status: '',
                visibility: '',
                file: '',
            }, '/api/media'),
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {},
            visibilites: [{value: 1, text: 'Public'}, {value: 0, text: 'Private'},{value: 2, text: 'Both'}],
            statuses: [{value: 1, text: 'Active'}, {value: 0, text: 'Inactive'}],
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Title', align: 'left', value: 'title'},
                {text: 'Description', align: 'left', value: 'description'},
                {text: 'Status', align: 'left', value: 'status'},
                {text: 'Visiblity', align: 'left', value: 'visiblity'},
                {text: 'File', align: 'left', value: 'file'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            }
        },

        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.save()
                }
            },
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.file = this.imagePicker.image;
                this.form.store().then(() => {
                    this.resetImagePicker();
                });
            },
            handleFileUpload() {
                this.form.file = this.$refs.file.files[0];
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName;

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .3})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },
            onPickFile() {
                this.$refs.image.click()
            },

        }
    }
</script>
<style lang="scss" scoped>
    .public {
        background: red;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
    .private {
        background: #fb8c00 !important;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
    .both {
        background: green;
        padding: 3px;
        border-radius: 5px;
        color: white;
    }
</style>